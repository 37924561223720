const APIService = require('../APIService');
const urlParams = require('../../../utils/urlParams');
const { ITEMS_PER_PAGE } = require('../../../utils/constants');
class Permissions extends APIService {
  constructor({ groupId, id }) {
    super();
    this._url = `/access-control/user-categories/${groupId}/permissions-profiles${id ? `/${id}` : ''}`;
    this._resetUrl = `/access-control/user-categories/set-default-permissions`;
  }

  async get() {
    try {
      const { data } = await this.httpClient.get(`${this._url}${urlParams({ limit: ITEMS_PER_PAGE })}`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async patch({ payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/multi-patch`, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async resetPermissions({ payload }) {
    try {
      const { data } = this.httpClient.post(`${this._resetUrl}`, payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

module.exports = Permissions;
