/* eslint max-lines: 0 */
/* eslint max-lines-per-function: 0 */
const BaseComponent = require('./Base');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Card = require('./Card');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toggle = require('./Toggle');
const dropDownSVG = require('../../assets/dropdown.svg');
const radioOffSVG = require('../../assets/radioOff.svg');
const radioOnSVG = require('../../assets/radioOn.svg');
const FacilitiesAPI = require('../services/api/facilities');
const ClinicDefinitionAPI = require('../services/api/clinicdefinition');
const {
  UPDATE_CRUD_OPERATION,
  FETCH_FACILITIES,
  FETCH_SPECIALITIES,
  UPDATE_SELECTED_FACILITY,
  UPDATE_SELECTED_SPECIALTY,
  TOAST,
  ERROR,
  SUCCESS,
} = require('../events');

const eventBus = require('../utils/EventBus');
const facilitiesAPI = new FacilitiesAPI();
const clinicDefinitionService = new ClinicDefinitionAPI();

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') != -1) {
    return ua.indexOf('chrome') < 0;
  }
};

class Workflow extends BaseComponent {
  constructor() {
    super();
    this._useEvents = true;
  }

  attachEvents() {
    if (this.selectedSpecialty) {
      const confirmButton = this.shadowRoot.querySelector('.confirm');
      const performing = this.shadowRoot.querySelector('select[name="performing"]');
      const attending = this.shadowRoot.querySelector('select[name="attending"]');
      const unassigned = this.shadowRoot.querySelector('select[name="unassigned"]');
      const radioOrderIcon = this.shadowRoot.querySelector('label.order');
      const radioEncounterIcon = this.shadowRoot.querySelector('label.encounter');
      const radioOrder = this.shadowRoot.querySelector('input.order');
      const radioEncounter = this.shadowRoot.querySelector('input.encounter');
      if (radioOrder) {
        radioOrderIcon.addEventListener('click', () => {
          radioEncounter.checked = false;
          radioOrder.checked = true;
        });

        radioEncounterIcon.addEventListener('click', () => {
          radioEncounter.checked = true;
          radioOrder.checked = false;
        });

        confirmButton.addEventListener('click', async e => {
          e.preventDefault();
          const workflow = radioOrder.checked ? 'ORDER_BASED' : radioEncounter.checked ? 'ENCOUNTER_BASED' : null;
          try {
            await clinicDefinitionService
              .facilities({ facilityId: this.selectedFacility })
              .specialties({ specialtyId: this.selectedSpecialty })
              .patch({
                payload: {
                  workflowType: workflow,
                  attendingTimestamp: attending.value,
                  performingTimestamp: performing.value,
                  unassignedTimestamp: unassigned.value,
                },
              });
            eventBus.fire(TOAST, {
              items: [
                {
                  type: SUCCESS,
                  message: 'Updated Successfully',
                },
              ],
            });
          } catch (error) {
            eventBus.fire(TOAST, {
              items: [
                {
                  type: ERROR,
                  message: error,
                },
              ],
            });
          }

          eventBus.fire(FETCH_SPECIALITIES, {
            data: await clinicDefinitionService
              .facilities({ facilityId: this.selectedFacility })
              .specialties()
              .get({
                parameters: {
                  is_enabled: true,
                },
              }),
          });
        });
      }
    }
  }

  async connectedCallback() {
    eventBus.register(UPDATE_CRUD_OPERATION, ({ detail }) => {
      this.selectedCard = detail.card;
      this.operation = detail.operation;
    });

    eventBus.register(UPDATE_SELECTED_FACILITY, ({ detail }) => {
      this.selectedFacility = detail.facilityId;
      this.selectedSpecialty = null;
    });

    eventBus.register(UPDATE_SELECTED_SPECIALTY, ({ detail }) => {
      this.selectedSpecialty = detail.specialtyId;
    });

    this.listenForEvents(
      [
        { event: FETCH_FACILITIES, key: 'facilities' },
        { event: FETCH_SPECIALITIES, key: 'specialities' },
      ],
      eventBus,
    );

    eventBus.fire(FETCH_FACILITIES, {
      data: await clinicDefinitionService.facilities().get({
        parameters: {
          is_enabled: true,
        },
      }),
    });
  }

  static get observedAttributes() {
    return ['selectedcard', 'operation', 'selectedfacility', 'selectedspecialty'];
  }

  set selectedCard(newSelectedCard) {
    this.setAttribute('selectedCard', newSelectedCard);
  }

  get selectedCard() {
    return this.getAttribute('selectedCard') || 'facilities';
  }

  set selectedFacility(newSelectedFacility) {
    this.setAttribute('selectedFacility', newSelectedFacility);
  }

  get selectedFacility() {
    return this.getAttribute('selectedFacility');
  }

  set operation(newOperation) {
    this.setAttribute('operation', newOperation);
  }

  get operation() {
    return this.getAttribute('operation') || 'view';
  }

  get selectedSpecialty() {
    return this.getAttribute('selectedSpecialty');
  }

  set selectedSpecialty(newSelectedSpecialty) {
    this.setAttribute('selectedSpecialty', newSelectedSpecialty);
  }

  styles() {
    return `
      .title {
        margin-bottom: 3%;
        font-family: HelveticaNowDisplay;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 50px;
        letter-spacing: 0.566667px;
        color: #2C2C2E;
      }

      .head-section {
        display: flex;
        justify-content: space-between;
        padding: 5%;
      }

      .divider {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
      }

      .items {
        padding: 0;
        width: 100%;
        margin-top: 1%;
      }

      .enable-all {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        width: 90%;
        margin: 0 auto;
        font-size: 10px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: rgba(44, 44, 46, 0.503087);
        padding-bottom: 3%;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
        padding-bottom: 5%;
      }

      .choose-label {
        margin-left: 0%;
        color: #969696;
      }

      .enable-all-label {
        margin-top: 1%;
        margin-right: 2%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      exo-card-button:last-child {
        margin-left: 10%;
      }

      .card-container {
        display: flex;
      }

      exo-card {
        width: 25%;
        margin-right: 2%;
      }

      exo-card-list-item:hover {
        cursor: pointer;
      }

      select {
        border: none;
        width: 100%;
        float: left;
        padding: 5px 10px;
        border-bottom: 1px solid #dbdbdb;
        outline: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-left: 5%;
      }

      label {
        text-align: left;
        margin-left: 5%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #dbdbdb;
      }

      .select-wrapper {
        width: 90%;
        background-color: #FFF;
        display: inline-block;
        position: relative;
      }

      .select-wrapper::before {
        content: url(${dropDownSVG});
        position: absolute;
        right: 0%;
      }

      fieldset {
        border: none;
      }

      .select-wrapper::after {
        content: "Hrs";
        position: absolute;
        color:  rgba(44, 44, 46, 0.5);
        right: 10%;
        z-index: 5;
        bottom: 15%;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.2px;
      }

      .radio-container {
        width: 90%;
        background-color: #f7f7f7;
        border-radius: 5px;
        margin: 0 auto;
        padding: 2%;
        margin-bottom: 2%;
        padding: 5%;
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.233333px;
        color: #2C2C2E;
        justify-content: space-between;
      }

      button {
        border: 2px solid black;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
      }


      .confirm {
        border-color: #007AFF;
        color: #007AFF;
        margin-bottom: 3%;
        margin-top: 3%;
        margin-left: ${isSafari() ? '41' : '48'}%;
        ${isSafari() ? `white-space: nowrap;` : ''}
      }

      .radio-label {
        margin-top: 1%;
      }

      .radio-label:hover {
        cursor: pointer;
      }

      input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
      }

      input[type="radio"]:not(:checked) ~ label > .radio-off {
        display: block;
      }

      input[type="radio"]:not(:checked) ~ label > .radio-on {
        display: none;
      }

      input[type="radio"]:checked ~ label > .radio-on {
        display: block;
      }

      input[type="radio"]:checked ~ label > .radio-off {
        display: none;
      }

      .card-content {
        height: 80vh;
        display: flex;
        flex-direction: column;
      }

      ul {
        overflow: auto;
      }

      .button-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
        .confirm {
          margin: 0;
        }
      }

      @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
        exo-card {
          width: 30%;
        }

        .confirm {
          margin: 0 0 0 15%;

        }
      }

      @media only screen and (min-height: 1000px) {
        .confirm {
          margin: 0 0 0 15%;
        }
      }

      @media only screen and (max-width: 768px) {
        exo-card {
          width: 50%;
        }
      }

      @media only screen and (max-width: 1220px) {
        button {
          font-size: 12px;
          padding: 7px 14px;
        }
      }
    `;
  }

  renderEfficiencyCard() {
    const specialty = this._data.specialities.find(spc => spc.id == this.selectedSpecialty);
    const workflowType = specialty ? specialty.workflowType : null;

    return `
      <exo-card page="workflow">
        <div class="head-section">
          <exo-card-title title="Efficiency"></exo-card-title>
        </div>
        <label for="performing">Performing</label>
          <div class="select-wrapper">
            <select name="performing">
              ${facilitiesAPI
                .getHours()
                .map(
                  hour => `
                <option value="${hour}" ${specialty && hour == specialty.performingTimestamp ? 'selected' : ''}>
                  ${hour}
                </option>
              `,
                )
                .join('')}
            </select>
          </div>
          <label for="attending">Attending</label>
          <div class="select-wrapper">
            <select name="attending">
              ${facilitiesAPI
                .getHours()
                .map(
                  hour => `
                <option value="${hour}"  ${specialty && hour == specialty.attendingTimestamp ? 'selected' : ''}>
                  ${hour}
                </option>
              `,
                )
                .join('')}
            </select>
          </div>
          <label for="Unassigned">Unassigned</label>
          <div class="select-wrapper">
            <select name="unassigned">
              ${facilitiesAPI
                .getHours()
                .map(
                  hour => `
                <option value="${hour}"  ${specialty && hour == specialty.unassignedTimestamp ? 'selected' : ''}>
                  ${hour}
                </option>
              `,
                )
                .join('')}
            </select>
          </div>
        <div class="head-section">
          <exo-card-title title="Exam workflow"></exo-card-title>
        </div>
        <form>
          <fieldset>
            <label class="choose-label">Choose one</label>
            <div class="radio-container">
              Encounter Based
              <input type="radio" class="encounter" name="based" value="Encounter" ${
                workflowType === 'ENCOUNTER_BASED' ? 'checked' : ''
              }>
              <label class="radio-label encounter">
                <svg width="15" height="15" class="radio-off">
                  <image xlink:href="${radioOffSVG}" width="15" height="15" />
                </svg>
                <svg width="15" height="15" class="radio-on">
                  <image xlink:href="${radioOnSVG}" width="15" height="15" />
                </svg>
            </label>
            </div>
            <div class="radio-container">
              Order Based
              <input type="radio" class="order" name="based" value="Order" ${
                workflowType === 'ORDER_BASED' ? 'checked' : ''
              }>
              <label class="radio-label order">
                <svg width="15" height="15" class="radio-off">
                  <image xlink:href="${radioOffSVG}" width="15" height="15" />
                </svg>
                <svg width="15" height="15" class="radio-on">
                  <image xlink:href="${radioOnSVG}" width="15" height="15" />
                </svg>
            </label>
            </div>
            <div class="button-wrapper">
              <button class="confirm"> Save Settings</button>
            </div>
          </fieldset>
        </form>
      </exo-card>
    `;
  }

  renderExoCard({ title, divider, items, card, operation, nameKey, selectedItem }) {
    return `
      <exo-card
        page="workflow"
        class="${this.selectedCard !== card && this.operation !== 'view' ? 'blur' : ''}">
        <div class="card-content">

          <div class="head-section ${card}">
            <exo-card-title title="${title}"></exo-card-title>
          </div>


          <div class="divider">
            ${divider.map(d => `<span>${d}</span>`).join('')}
          </div>

          <ul class="items">
            ${items
              .map(
                item =>
                  `<exo-card-list-item
                    page="workflow"
                    name="${item.name}"
                    operation="${operation}"
                    id="${item.id}"
                    ${card === 'workflow' ? '' : 'viewOnly'}
                    card="${card}"
                    model="${item.model}"
                    enabled="${item.enabled}"
                    facilityId="${this.selectedFacility}"
                    specialtyId="${this.selectedSpecialty}"
                    selectedItem="${selectedItem}"
                    clickable="true"
                  >
                    ${item[nameKey]}
                  </exo-card-list-item>`,
              )
              .join('')}
          </ul>
        </div>
      </exo-card>
    `;
  }

  render() {
    return `
      <div>
        <div class="title">Workflow</div>
        <div class="card-container">
          ${
            this._data && this._data.facilities
              ? this.renderExoCard({
                  card: 'facilities',
                  title: 'Facility',
                  divider: ['Facility'],
                  nameKey: 'name',
                  operation: this.selectedCard === 'facilities' ? this.operation : 'view',
                  items: this._data.facilities,
                  selectedItem: this.selectedFacility,
                })
              : `<span> Loading... </span>`
          }
          ${
            this._data && this._data.specialities
              ? this.renderExoCard({
                  card: 'specialities',
                  title: 'Specialities',
                  nameKey: 'name',
                  divider: ['Specialities'],
                  operation: this.selectedCard === 'specialities' ? this.operation : 'view',
                  items: this._data.specialities,
                  selectedItem: this.selectedSpecialty,
                })
              : ''
          }
          ${this._data && parseInt(this.selectedSpecialty) ? this.renderEfficiencyCard() : ''}
        </div>
      </div>
    `;
  }
}

customElements.define('workflow-page', Workflow);
