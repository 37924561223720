const APIService = require('../APIService');
const formatError = require('../../../utils/formatError');
const urlParams = require('../../../utils/urlParams');
const { ITEMS_PER_PAGE } = require('../../../utils/constants');


class UserGroups extends APIService {
  constructor({ facilityId, specialtyId }) {
    super();
    this._url = `/clinic-definition/facilities/${facilityId}/specialties/${specialtyId}/user-categories`;
  }

  async get() {
    try {
      const params = { limit: ITEMS_PER_PAGE };
      const { data } = await this.httpClient.get(`${this._url}${urlParams(params)}`);
      return data.results;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async patch({ id, payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async enableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/enable-all`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async disableAll() {
    try {
      const { data } = await this.httpClient.post(`${this._url}/disable-all`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  qaSettings({ groupId, qaSettingId }) {
    return {
      get: async () => {
        const { data } = await this.httpClient.get(
          `${this._url}/${groupId}/qa-settings${qaSettingId ? `/${qaSettingId}` : ''}`,
        );
        return data;
      },
      patch: async ({ payload }) => await this.httpClient.patch(`${this._url}/${groupId}/patch-qa-settings`, payload),
    };
  }
}

module.exports = UserGroups;
