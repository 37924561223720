const APIService = require('../APIService');
const formatError = require('../../../utils/formatError');
const { ITEMS_PER_PAGE } = require('../../../utils/constants');

class GlobalTags extends APIService {
  constructor() {
    super();
    this._url = `/clinic-definition/global-tags`;
  }

  async getAll({ offset, limit }) {
    try {
      const { data } = await this.httpClient.get(
        `${this._url}${offset && limit ? `?offset=${offset}&limit=${limit}` : `?limit=${ITEMS_PER_PAGE}`}`,
      );
      return { results: data.results, offset, count: data.count };
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async get({ id }) {
    try {
      const { data } = await this.httpClient.get(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async patch({ id, payload }) {
    try {
      const { data } = await this.httpClient.patch(`${this._url}/${id}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async create({ payload }) {
    try {
      const { data } = await this.httpClient.post(`${this._url}`, payload);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }

  async delete({ id }) {
    try {
      const { data } = await this.httpClient.delete(`${this._url}/${id}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = GlobalTags;
