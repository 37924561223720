const APIService = require('../APIService');
const urlParams = require('../../../utils/urlParams');
const formatError = require('../../../utils/formatError');
const { ITEMS_PER_PAGE } = require('../../../utils/constants');

class ExamWorksheets extends APIService {
  constructor() {
    super();
    this._url = `/clinic-definition/exam-worksheets/`;
  }

  async getAll({ parameters }) {
    try {
      const params = { ...parameters, limit: ITEMS_PER_PAGE };
      const { data } = await this.httpClient.get(`${this._url}${urlParams(params)}`);
      return data;
    } catch (error) {
      throw new Error(formatError(error));
    }
  }
}

module.exports = ExamWorksheets;
